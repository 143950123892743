
import { Component, Vue } from 'vue-property-decorator'
import Drawer from '@/layouts/components/Drawer.vue'
import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')
import { UserModel } from '@/core/models/UserModel'

@Component({
  name: 'StickyLayout',
  components: { Drawer },
})
export default class extends Vue {
  @UserStore.Getter
  public isAuth!: boolean

  @UserStore.Action
  private getUser!: () => Promise<UserModel>

  public created(): void {
    this.getUser()
  }

  public mounted(): void {
    /*if (!this.isAuth) {
      this.$router.push({ name: 'Login' })
    }*/

    const s = document.createElement('script')
    s.async = true
    s.src =
      'https://cdn-ru.bitrix24.ru/b13775582/crm/site_button/loader_2_pucfpy.js' +
      '?' +
      ((Date.now() / 60000) | 0)
    const h = document.getElementsByTagName('script')[0]
    h?.parentNode?.insertBefore(s, h)
  }
}
